import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';

import { withCssUnit } from 'common/components/styleguide/utils';

import Image from 'common/components/_base/Image';
import Price from 'common/components/_base/Price';
import AddToCart from 'common/blocks/functional/addToCartButton';
import RouteLink from 'common/components/_base/RouteLink';

const S = {};

S.GridItem = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => withCssUnit(_.get(theme, 'gutterWidth', 0))};

  background: ${({ theme }) =>
    _.get(theme, 'productCard.background', 'transparent')};
  ${({ theme }) =>
    _.get(theme, 'productCard.border')
      ? `border: ${_.get(theme, 'productCard.border')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'productCard.transition')
      ? `transition: ${_.get(theme, 'productCard.transition')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'productCard.borderRadius')
      ? `border-radius: ${withCssUnit(
          _.get(theme, 'productCard.borderRadius')
        )};`
      : ''}
  &:hover {
    ${({ theme }) =>
      _.get(theme, 'productCard.hover.border')
        ? `border: ${_.get(theme, 'productCard.hover.border')};`
        : ''}
    ${({ theme }) =>
      _.get(theme, 'productCard.hover.boxShadow')
        ? `box-shadow: ${_.get(theme, 'productCard.hover.boxShadow')};`
        : ''}
  }

  margin-bottom: ${({ theme }) => withCssUnit(_.get(theme, 'gutterWidth', 0))};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 0;
  }
`;

S.ItemNameLink = styled(({ children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  display: block;
  color: ${({ theme }) => _.get(theme, 'productCard.name.color', '#999')};
  font-size: 1.2em;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => _.get(theme, 'productCard.name.color', '#999')};
  }
`;

S.ImageLink = styled(({ children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  display: flex;
  width: 100%;
  height: 320px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

S.ItemBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
`;

class Item extends Component {
  render() {
    const { data, t } = this.props;
    const {
      photo,
      name,
      urlTitle,
      finalPrice,
      finalPriceWithoutDPH,
      price,
      discount,
      measureUnit
    } = data;
    return (
      <S.GridItem>
        <S.ImageLink urlTitle={urlTitle}>
          <Image {...photo} size="340x340.png" />
        </S.ImageLink>
        <S.ItemNameLink urlTitle={urlTitle}>{name}</S.ItemNameLink>
        <S.ItemBottom>
          <div>
            <Price
              className="product-grid__price"
              finalPrice={finalPrice}
              finalPriceWithoutDPH={finalPriceWithoutDPH}
              price={price}
              measureUnit={measureUnit}
              discount={discount}
            />
          </div>
          <div>
            <AddToCart
              title={'add to cart'}
              t={t}
              product={data}
            />
          </div>
        </S.ItemBottom>
      </S.GridItem>
    );
  }
}

export default withTranslation()(Item);
