import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';

import { withCssUnit } from 'common/components/styleguide/utils';

import AddToCart from 'common/blocks/functional/addToCartButton';

import RouteLink from 'common/components/_base/RouteLink';
import Price from 'common/components/_base/Price';
import Image from 'common/components/_base/Image';

const S = {};

S.Item = styled.div`
  height: 240px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) =>
    _.get(theme, 'productCard.background', 'transparent')};
  ${({ theme }) =>
    _.get(theme, 'productCard.border')
      ? `border: ${_.get(theme, 'productCard.border')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'productCard.transition')
      ? `transition: ${_.get(theme, 'productCard.transition')};`
      : ''}
  ${({ theme }) =>
    _.get(theme, 'productCard.borderRadius')
      ? `border-radius: ${withCssUnit(
          _.get(theme, 'productCard.borderRadius')
        )};`
      : ''}
  &:hover {
    ${({ theme }) =>
      _.get(theme, 'productCard.hover.border')
        ? `border: ${_.get(theme, 'productCard.hover.border')};`
        : ''}
    ${({ theme }) =>
      _.get(theme, 'productCard.hover.boxShadow')
        ? `box-shadow: ${_.get(theme, 'productCard.hover.boxShadow')};`
        : ''}
  }
  margin-bottom: ${({ theme }) => withCssUnit(_.get(theme, 'gutterWidth', 0))};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 0;
  }
`;

S.ItemBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

S.ImageLink = styled(({ children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  display: flex;
  width: 100%;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
`;

S.ItemNameLink = styled(({ children, urlTitle, ...props }) => {
  if (!_.isEmpty(urlTitle) && _.isString(urlTitle)) {
    return (
      <RouteLink route="product" params={{ id: urlTitle }} {...props}>
        {children}
      </RouteLink>
    );
  }
  return <div {...props}>{children}</div>;
})`
  color: ${({ theme }) => _.get(theme, 'productCard.name.color', '#999')};
  display: block;
  text-decoration: none;
  line-height: 1.2;
  font-size: ${({ nameLength }) => {
    let fontSize;
    if (nameLength > 60) {
      fontSize = '0.7em';
    } else {
      fontSize = '0.9em';
    }
    return fontSize;
  }};
  font-weight: 600;
  margin-bottom: 5px;
  &:hover {
    color: ${({ theme }) => _.get(theme, 'productCard.name.color', '#999')};
  }
`;

S.ItemDown = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: space-between;
`;

class Item extends PureComponent {
  render() {
    const { data, t } = this.props;
    const {
      photo,
      name,
      urlTitle,
      finalPrice,
      finalPriceWithoutDPH,
      price,
      discount,
      measureUnit
    } = data;
    return (
      <S.Item>
        <S.ImageLink urlTitle={urlTitle}>
          <Image {...photo} size="200x100.jpg" />
        </S.ImageLink>
        <S.ItemDown>
          <S.ItemNameLink urlTitle={urlTitle} nameLength={name.length}>
            {name}
          </S.ItemNameLink>
          <S.ItemBottom>
            <div>
              <Price
                finalPrice={finalPrice}
                finalPriceWithoutDPH={finalPriceWithoutDPH}
                price={price}
                discount={discount}
                measureUnit={measureUnit}
              />
            </div>
            <div>
              <AddToCart t={t} product={data} buttonSize="small" />
            </div>
          </S.ItemBottom>
        </S.ItemDown>
      </S.Item>
    );
  }
}

export default withTranslation()(Item);
