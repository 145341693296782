import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { withCssUnit } from 'common/components/styleguide/utils';
import {
  Container,
  Row,
  Column,
  BlockSecondary
} from 'common/components/styleguide/grid';
import ItemBig from './ItemBig';
import ItemSmall from './ItemSmall';

const S = {};

S.TopRowWrapper = styled.div`
  margin-bottom: 0;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    margin-bottom: 20px;
  }
`;

S.ColumnLast = styled(Column)`
  display: none;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.lg)}) {
    display: block;
  }
`;

const NewProductsVariant1 = ({ data, theme }) => {
  if (_.isEmpty(data)) {
    return null;
  }
  const productsLength = data.length;
  if (productsLength < 5) {
    return null;
  }
  return (
    <BlockSecondary padding={_.get(theme, 'gutterWidth', 0) + 'px ' + (_.get(theme, 'gutterWidth', 0) ? _.get(theme, 'gutterWidth', 0) / 2 : 0) + 'px'} marginConfig={{}}>
      <Container>
        <Row>
          <Column size={{ xs: 12, md: 6, lg: 4 }}>
            <ItemBig data={data[0]} />
          </Column>
          <Column size={{ xs: 12, md: 6, lg: 8 }}>
            <S.TopRowWrapper>
              <Row>
                <Column size={{ xs: 6, md: 6, lg: 4 }}>
                  <ItemSmall data={data[1]} />
                </Column>
                {productsLength >= 5 && (
                  <Column size={{ xs: 6, lg: 4 }}>
                    <ItemSmall data={data[2]} />
                  </Column>
                )}
                {productsLength >= 7 && (
                  <S.ColumnLast size={{ xs: 4 }}>
                    <ItemSmall data={data[5]} />
                  </S.ColumnLast>
                )}
              </Row>
            </S.TopRowWrapper>
            <Row>
              <Column size={{ xs: 6, md: 6, lg: 4 }}>
                <ItemSmall data={data[3]} />
              </Column>
              {productsLength >= 5 && (
                <Column size={{ xs: 6, lg: 4 }}>
                  <ItemSmall data={data[4]} />
                </Column>
              )}
              {productsLength >= 7 && (
                <S.ColumnLast size={{ xs: 4 }}>
                  <ItemSmall data={data[6]} />
                </S.ColumnLast>
              )}
            </Row>
          </Column>
        </Row>
      </Container>
    </BlockSecondary>
  );
};

export default React.memo(NewProductsVariant1);
